import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

enum Breakpoints {
  XS = '(max-width: 600px)',
  S = '(min-width: 601px) and (max-width: 1023.99px)',
  M = '(min-width: 1024px) and (max-width: 1439.99px)',
  L = '(min-width: 1440px) and (max-width: 1919.99px)',
  XL = '(min-width: 1920px)',
}

const titleConfig = [
  { urlChunk: '/device/', title: 'Device Management' },
  { urlChunk: 'catalog', title: 'Model Catalogue' },
  { urlChunk: 'routing', title: 'Data Routing' },
  { urlChunk: 'users/new', title: 'User Creation' },
  { urlChunk: 'users/edit', title: 'Edit User' },
  { urlChunk: 'users', title: 'User Management' },
  { urlChunk: 'site/new', title: 'Site Creation' },
  { urlChunk: 'site/edit', title: 'Edit Site' },
  { urlChunk: 'site', title: 'Site Management' },
  { urlChunk: 'interfaces', title: 'Interface Management' },
  { urlChunk: 'gateways', title: 'Gateways' },
  { urlChunk: 'alerts', title: 'Alerts' },
  { urlChunk: '', title: 'Home' },
];

@Injectable({
  providedIn: 'root',
})
export class InterfaceStateService {
  private readonly _breakpointIndex$ = new BehaviorSubject<number>(0);
  private readonly _mainTitle$ = new BehaviorSubject<string>('');
  private readonly _routedUrlHistory$ = new BehaviorSubject<string[]>([]);
  private readonly _dashboardFilter$ = new BehaviorSubject<UntypedFormGroup>(
    this.buildDashBoardFilter(),
  );
  private readonly _routingFilter$ = new BehaviorSubject<UntypedFormGroup>(
    this.buildRoutingFilter(),
  );

  constructor(
    private breakPointObserver: BreakpointObserver,
    private router: Router,
  ) {
    this.mainTitleHandler$.subscribe();
    this.breakpointHandler$.subscribe();
    this.lastRoutedUrlHandler$.subscribe();
  }

  public get breakpointIndex$(): Observable<number> {
    return this._breakpointIndex$.asObservable();
  }

  public get mainTitle$(): Observable<string> {
    return this._mainTitle$.asObservable();
  }

  public get routedUrlHistory$(): Observable<string[]> {
    return this._routedUrlHistory$.asObservable();
  }

  public get lastRoutedUrl(): string {
    const history = this._routedUrlHistory$.value;
    return history[history.length - 2];
  }

  private addUrlToHistory(url: string): void {
    const history = this._routedUrlHistory$.value;
    history.push(url);
    this._routedUrlHistory$.next(history);
  }

  private mapBreakpointToIndex(breakpoints: Record<string, boolean>) {
    if (breakpoints[Breakpoints.S]) {
      return 1;
    }
    if (breakpoints[Breakpoints.M]) {
      return 2;
    }
    if (breakpoints[Breakpoints.L]) {
      return 3;
    }
    if (breakpoints[Breakpoints.XL]) {
      return 4;
    }
    return 0;
  }

  public resetStore() {
    this.resetDashBoardFilter();
    this.resetRoutingFilter();
  }

  public setDashboardFilter(filterForm: UntypedFormGroup) {
    this._dashboardFilter$.next(filterForm);
  }

  public get dashboardFilter$(): Observable<UntypedFormGroup> {
    return this._dashboardFilter$.asObservable();
  }

  public get dashboardFilter(): UntypedFormGroup {
    return this._dashboardFilter$.value;
  }

  public resetDashBoardFilter(): void {
    this._dashboardFilter$.next(this.buildDashBoardFilter());
  }

  public buildDashBoardFilter(
    siteId = '',
    brandName = '',
    source = '',
    deviceType = '',
    status = '',
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      siteId: new UntypedFormControl(siteId),
      brand_name: new UntypedFormControl(brandName),
      source: new UntypedFormControl(source),
      device_type_id: new UntypedFormControl(deviceType),
      status: new UntypedFormControl(status),
    });
  }

  public setRoutingFilter(filterForm: UntypedFormGroup): void {
    this._routingFilter$.next(filterForm);
  }

  public get routingFilter$(): Observable<UntypedFormGroup> {
    return this._routingFilter$.asObservable();
  }

  public resetRoutingFilter(): void {
    this._routingFilter$.next(this.buildRoutingFilter());
  }

  public buildRoutingFilter(
    keyword = '',
    site = null,
    destination = null,
    tileView = true,
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      filter: new UntypedFormGroup({
        keyword: new UntypedFormControl(keyword),
        site: new UntypedFormControl(site),
        destination: new UntypedFormControl(destination),
      }),
      tileView: new UntypedFormControl(tileView),
    });
  }

  private get mainTitleHandler$(): Observable<string> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),

      map((event) => event as NavigationEnd),
      map((event) => event.url),
      map(
        (url) =>
          titleConfig.filter((ele) => url.includes(ele.urlChunk))[0].title,
      ),
      tap((title) => this._mainTitle$.next(title)),
    );
  }

  private get breakpointHandler$(): Observable<number> {
    return this.breakPointObserver
      .observe([
        Breakpoints.XS,
        Breakpoints.S,
        Breakpoints.M,
        Breakpoints.L,
        Breakpoints.XL,
      ])
      .pipe(
        map((state) => this.mapBreakpointToIndex(state.breakpoints)),
        tap((index) => this._breakpointIndex$.next(index)),
      );
  }

  private get lastRoutedUrlHandler$(): Observable<string> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url),
      tap((url) => this.addUrlToHistory(url)),
    );
  }
}
