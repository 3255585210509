import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Gateway } from '../../../models/gateway.models';
import { Paginated } from '../../../models/pagination.models';

@Injectable({
  providedIn: 'root',
})
export class GatewaysService {
  constructor(private readonly http: HttpClient) {}

  getList(query: GetGatewaysQuery): Observable<Paginated<Gateway>> {
    const params = Object.fromEntries(
      Object.entries(query)
        // remove empty or undefined filters
        .filter(([_key, value]) => value !== undefined && value !== null)
        // transform the date into a string
        .map(([key, value]) =>
          key === 'lastCheckinAfter'
            ? [key, (value as Date).toISOString()]
            : [key, value as string | number],
        ),
    );
    return this.http.get<Paginated<Gateway>>(
      `${environment.apiUrlConfigs}gateways`,
      { params },
    );
  }
}

export type GetGatewaysQuery = {
  offset?: number;
  limit?: number;
  siteId?: string;
  lastCheckinAfter?: Date;
};
