import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { MaterialModule } from './material/material.module';

import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CounterChipComponent } from 'src/app/components/counter-chip/counter-chip.component';
import { DataLayoutItemComponent } from '../components/data-layout/data-layout-item/data-layout-item.component';
import { DataLayoutComponent } from '../components/data-layout/data-layout.component';
import { DisplayPositionMapComponent } from '../components/display-position-map/display-position-map.component';
import { FormFieldComponent } from '../components/form-field/form-field.component';
import { FormStepperComponent } from '../components/form-stepper/form-stepper.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { NoResultComponent } from '../components/no-result/no-result.component';
import { ModelFilterComponent } from '../pages/main/catalogue/model-filter/model-filter.component';
import { ModelItemComponent } from '../pages/main/catalogue/model-item/model-item.component';
import { DataPreviewComponent } from '../pages/main/device-management/device-detail/data-preview/data-preview.component';
import { ImageUploadComponent } from './../components/image-upload/image-upload.component';
import { AutofocusDirective } from './util/auto-focus.directive';
import { LoaderDirective } from './util/loader.directive';

const COMPONENTS = [
  DisplayPositionMapComponent,
  DataPreviewComponent,
  LoaderComponent,
  AutofocusDirective,
  FormFieldComponent,
  ImageUploadComponent,
  ModelItemComponent,
  ModelFilterComponent,
  ConfirmationDialogComponent,
  CounterChipComponent,
  FormStepperComponent,
  NoResultComponent,
  DataLayoutComponent,
  DataLayoutItemComponent,
];

@NgModule({
  declarations: [COMPONENTS, LoaderDirective],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    CdkTableModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [],
  exports: [
    MaterialModule,
    CdkTableModule,
    AngularFireModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    COMPONENTS,
    LoaderDirective,
  ],
})
export class SharedModule {}
