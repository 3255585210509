<mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons>
</mat-paginator>
<table
  mat-table
  matSort
  matSortActive="sentTimestamp"
  matSortDirection="desc"
  matSortDisableClear="true"
  [dataSource]="dataSource"
>
  <ng-container matColumnDef="messageId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Message Id</th>
    <td mat-cell *matCellDef="let message" class="messageId">
      {{ message.messageId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="sentTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent Date</th>
    <td mat-cell *matCellDef="let message" class="sentTimestamp">
      {{ message.sentTimestamp | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="body">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Body</th>
    <td
      mat-cell
      *matCellDef="let message"
      class="body"
      [matTooltip]="message.body"
    >
      {{ message.body }}
    </td>
  </ng-container>
  <ng-container matColumnDef="approximateReceiveCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Receive</th>
    <td mat-cell *matCellDef="let message" class="approximateReceiveCount">
      {{ message.approximateReceiveCount }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
