import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MesMessageDto } from '../../../../../models/mes-messages';

@Component({
  selector: 'app-redrive-messages-table',
  templateUrl: './redrive-messages-table.component.html',
  styleUrls: ['./redrive-messages-table.component.scss'],
})
export class RedriveMessagesTableComponent implements AfterViewInit {
  @Input()
  messages: MesMessageDto[] = [];

  public dataSource = new MatTableDataSource<MesMessageDto>(this.messages);
  public displayedColumns: string[] = [
    'messageId',
    'sentTimestamp',
    'body',
    'approximateReceiveCount',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refresh(): void {
    this.dataSource.data = this.messages;
  }
}
