import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  icon,
  latLng,
  latLngBounds,
  map,
  marker,
  markerClusterGroup,
  tileLayer,
} from 'leaflet';
import 'leaflet.markercluster';
import { environment } from 'src/environments/environment';
import { Gateway } from 'src/models/gateway.models';

const mapOptions = {
  layers: [
    tileLayer(environment.openStreetMap.layerTilesUrl, {
      maxZoom: 18,
      attribution: environment.openStreetMap.attribution,
    }),
  ],
};

@Component({
  selector: 'app-gateway-map',
  templateUrl: './gateway-map.component.html',
  styleUrls: ['./gateway-map.component.scss'],
})
export class GatewayMapComponent implements AfterViewInit, OnDestroy {
  @Input() set gateways(gw: Gateway[]) {
    this.bounds = this.mapGatewaysToBounds(gw);
    this.map
      ? this.updateMap(gw)
      : (this.markerLayer = this.mapGatewaysToMarkerLayer(gw));
  }
  public mapContainerId = Date.now().toString();
  private map: L.Map;
  private markerLayer: L.LayerGroup = markerClusterGroup();
  private bounds: L.LatLngBounds;

  ngAfterViewInit() {
    if (document.getElementById(this.mapContainerId)) {
      this.map = map(this.mapContainerId, mapOptions);
      this.markerLayer.addTo(this.map);
      this.map.fitBounds(this.bounds);
    }
  }

  private updateMap(gateways: Gateway[]): void {
    this.map.removeLayer(this.markerLayer);
    this.markerLayer = this.mapGatewaysToMarkerLayer(gateways).addTo(this.map);
    this.map.fitBounds(this.bounds);
  }

  private mapGatewaysToMarkerLayer(gateways: Gateway[]): L.LayerGroup {
    const cluster = markerClusterGroup({ showCoverageOnHover: false });
    gateways
      .filter((gateway) => gateway.location)
      .map((gateway) =>
        marker(
          latLng(gateway.location!.latitude, gateway.location!.longitude),
          {
            icon: icon({
              iconSize: [25, 41],
              iconAnchor: [13, 41],
              iconUrl: 'assets/img/marker-icon.png',
              shadowUrl: 'assets/img/marker-shadow.png',
            }),
          },
        ).bindTooltip(`${gateway.name}`),
      )
      .forEach((mkr) => cluster.addLayer(mkr));
    return cluster;
  }

  private mapGatewaysToBounds(gateways: Gateway[]): L.LatLngBounds {
    const coords = gateways
      .filter((gateway) => gateway.location)
      .map((gateway) =>
        latLng(gateway.location!.latitude, gateway.location!.longitude),
      );
    return coords.length === 0
      ? latLngBounds([
          [
            environment.openStreetMap.defaultMapCenter.lat,
            environment.openStreetMap.defaultMapCenter.lng,
          ],
        ])
      : latLngBounds(coords);
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
