<div class="header" *ngIf="!!model">
  <img
    class="model-image"
    [src]="
      model?.metadata?.device_logo_url || './assets/img/no_image_available.png'
    "
    alt="Device photo"
  />
  <div>
    <h1>{{ model?.metadata?.label }}</h1>
  </div>
  <div class="action-container">
    <div></div>
    <div class="actions">
      <button
        mat-mini-fab
        color="primary"
        (click)="onEdit()"
        matTooltipPosition="below"
        matTooltip="edit device type"
        *ngIf="model.metadata.created_by_api && auth.isAdmin()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        *ngIf="auth.isAdmin()"
        mat-mini-fab
        color="primary"
        (click)="toggleDisable()"
        matTooltipPosition="below"
        matTooltip="disable device type"
      >
        <mat-icon *ngIf="!model.disabled">do_not_disturb_on</mat-icon>
        <mat-icon *ngIf="model.disabled">do_not_disturb_off</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        (click)="delete()"
        matTooltipPosition="below"
        matTooltip="delete device type"
        *ngIf="model.metadata.created_by_api && auth.isAdmin()"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        (click)="close()"
        matTooltipPosition="below"
        matTooltip="return to catalogue"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="content" *ngIf="!!model">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Detais">
      <div class="details-content">
        <div class="details-box description">
          <div class="details-icon">
            <mat-icon color="white">menu_book</mat-icon>
          </div>
          <div class="details-text">
            <h4>Description</h4>
            <p>{{ model?.metadata?.description || noInfoMsg }}</p>
          </div>
        </div>
        <div class="details-box manufacturer">
          <div class="details-icon">
            <mat-icon color="white">factory</mat-icon>
          </div>
          <div class="details-text">
            <h4>Manufacturer Information</h4>
            <p>{{ model?.brand.name || noInfoMsg }}</p>
          </div>
        </div>
        <div class="details-box">
          <div class="details-icon">
            <mat-icon color="white">construction</mat-icon>
          </div>
          <div class="details-text">
            <h4>Installation Notes</h4>
            <p>{{ model?.metadata?.installation || noInfoMsg }}</p>
          </div>
        </div>
        <div class="details-box highlights">
          <div class="details-icon">
            <mat-icon color="white">stars</mat-icon>
          </div>
          <div class="details-text">
            <h4>Highlights</h4>
            <p>{{ model?.metadata?.highlights || noInfoMsg }}</p>
          </div>
        </div>
        <div class="details-box technical">
          <div class="details-icon">
            <mat-icon color="white">build</mat-icon>
          </div>
          <div class="details-text">
            <h4>Technical Specifications</h4>
            <div class="technical-spec-grid">
              <div>
                <h5>Configuration</h5>
                <p>{{ model?.metadata?.configuration || '-' }}</p>
              </div>
              <div>
                <h5>Connectivity</h5>
                <p>{{ model?.metadata?.connectivity || '-' }}</p>
              </div>
              <div>
                <h5>Frequency</h5>
                <p>{{ model?.metadata?.frequency || '-' }}</p>
              </div>
              <div>
                <h5>Security</h5>
                <p>{{ model?.metadata?.security || '-' }}</p>
              </div>
              <div>
                <h5>Dimensions</h5>
                <p>{{ model?.metadata?.dimensions || '-' }}</p>
              </div>
              <div>
                <h5>Weight</h5>
                <p>{{ model?.metadata?.weight || '-' }}</p>
              </div>
              <div>
                <h5>Battery</h5>
                <p>{{ model?.metadata?.battery || '-' }}</p>
              </div>
              <div>
                <h5>Battery Life</h5>
                <p>{{ model?.metadata?.battery_life || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Parser" *ngIf="auth.isAdmin()">
      <app-parser-tab [model]="model"></app-parser-tab>
    </mat-tab>
  </mat-tab-group>
</div>
