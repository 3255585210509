import { Component, ContentChildren, QueryList } from '@angular/core';
import { DataLayoutItemComponent } from './data-layout-item/data-layout-item.component';

@Component({
  selector: 'app-data-layout',
  templateUrl: './data-layout.component.html',
  styleUrls: ['./data-layout.component.scss'],
})
export class DataLayoutComponent {
  @ContentChildren('app-data-layout-item')
  items!: QueryList<DataLayoutItemComponent>;
}
