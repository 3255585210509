import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { firstValueFrom } from 'rxjs';
import { MesMessageDto, RedriveTaskDto } from '../../../../models/mes-messages';
import { DevicesService } from '../../../services/http/devices.service';
import { AuthService } from '../../../services/state/auth/auth.service';
import { hasOwnProperty } from '../../../services/util/utils';
import { RedriveMessagesSettingsComponent } from './redrive-messages-settings/redrive-messages-settings.component';
import { RedriveMessagesTableComponent } from './redrive-messages-table/redrive-messages-table.component';

@Component({
  selector: 'app-redrive-messages',
  templateUrl: './redrive-messages.component.html',
  styleUrls: ['./redrive-messages.component.scss'],
})
export class RedriveMessagesComponent implements OnInit, OnDestroy {
  public approximateNumberOfMessages: number | undefined;
  public redriveTask: RedriveTaskDto | undefined;
  public interval = 0;

  public maximumMessageCount = 20;
  public pollingDurationSeconds = 10;
  public pollingProgressPercent = 0;
  public pollingCancelled = false;
  public polling = false;

  private messages: MesMessageDto[] = [];

  @ViewChild(RedriveMessagesTableComponent)
  table: RedriveMessagesTableComponent;

  constructor(
    public readonly auth: AuthService,
    private readonly http: DevicesService,
    private readonly snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  private refreshData(): void {
    this.getApproximateNumberOfMessages();
    this.getLastRedriveTask();
  }

  private async getApproximateNumberOfMessages(): Promise<void> {
    this.approximateNumberOfMessages = await firstValueFrom(
      this.http.mesDeadLetterQueueGetApproximateNumberOfMessages(),
    );
  }

  private async getLastRedriveTask(): Promise<void> {
    this.redriveTask = await firstValueFrom(
      this.http.mesDeadLetterQueueGetLastRedriveTask(),
    );
  }

  openPollSettings(): void {
    const dialogRef = this.dialog.open(RedriveMessagesSettingsComponent, {
      width: '350px',
      data: {
        maximumMessageCount: this.maximumMessageCount,
        pollingDurationSeconds: this.pollingDurationSeconds,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.maximumMessageCount = result.dialogResult.maximumMessageCount;
        this.pollingDurationSeconds =
          result.dialogResult.pollingDurationSeconds;
      }
    });
  }

  public async cancelPolling(): Promise<void> {
    this.pollingCancelled = true;
  }

  public async pollMessages(): Promise<void> {
    try {
      this.polling = true;
      this.pollingProgressPercent = 0;
      this.messages = [];
      this.table?.refresh();
      const pollingEndDate = new Date();
      pollingEndDate.setSeconds(
        new Date().getSeconds() + this.pollingDurationSeconds,
      );
      while (new Date() < pollingEndDate) {
        if (this.pollingCancelled) {
          break;
        }
        this.pollingProgressPercent =
          100 -
          +(+pollingEndDate - +new Date()) / this.pollingDurationSeconds / 10;
        await this.pollMessage();

        if (this.messages.length >= this.maximumMessageCount) {
          break;
        }
      }
      this.pollingProgressPercent = 100;
    } catch (error) {
      const message = hasOwnProperty(error, 'message') ? error.message : error;
      this.snackBar.open(message as string, 'close');
      throw error;
    } finally {
      this.polling = false;
      this.pollingCancelled = false;
    }
  }

  public async pollMessage(): Promise<void> {
    const messages = await firstValueFrom(
      this.http.mesDeadLetterQueuePollMessages(),
    );
    if (messages) {
      for (const message of messages) {
        if (!this.messages.find((o) => o.messageId === message.messageId)) {
          this.messages.push(message);
        }
      }

      this.table.refresh();
    }
  }

  public async redriveMessages(): Promise<void> {
    await firstValueFrom(this.http.mesDeadLetterQueueRedrive());
    await this.getLastRedriveTask();
  }
}
