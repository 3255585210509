import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export class RedriveMessagesSettings {
  maximumMessageCount: number;
  pollingDurationSeconds: number;
}

@Component({
  selector: 'app-redrive-messages-settings',
  templateUrl: './redrive-messages-settings.component.html',
  styleUrls: ['./redrive-messages-settings.component.scss'],
})
export class RedriveMessagesSettingsComponent {
  form = this.buildForm;

  constructor(
    public dialogRef: MatDialogRef<RedriveMessagesSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RedriveMessagesSettings,
  ) {}

  private get buildForm(): FormGroup {
    return new FormGroup({
      maximumMessageCount: new FormControl(
        this.data.maximumMessageCount,
        Validators.min(1),
      ),
      pollingDurationSeconds: new FormControl(
        this.data.pollingDurationSeconds,
        Validators.min(1),
      ),
    });
  }

  submit() {
    this.dialogRef.close({ dialogResult: this.form.value });
  }
}
