<div *ngIf="auth.isTechnicalAdmin()">
  <div class="banner mat-typography mat-elevation-z3">
    <h2>MES Dead Letter Queue Redrive Messages</h2>
  </div>
  <div class="split">
    <section>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <div class="title">
              <div>Inspect messages<i> - Optional</i></div>
              <div>
                <mat-icon
                  class="nav-icon"
                  color="primary"
                  matTooltip="When a message is polled, it will not be visible until visibility timeout expires (default is 30s)."
                  >info
                </mat-icon>
              </div>
            </div>
            <div class="actions">
              <button
                mat-raised-button
                color="secondary"
                (click)="openPollSettings()"
                *ngIf="!polling"
              >
                Edit poll settings
              </button>
              <button
                mat-raised-button
                color="secondary"
                (click)="pollMessages()"
                *ngIf="!polling"
              >
                Poll for messages
              </button>
              <button
                mat-raised-button
                color="secondary"
                (click)="cancelPolling()"
                *ngIf="polling"
              >
                Cancel polling
              </button>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-data-layout>
            <app-data-layout-item title="Messages available">
              {{ approximateNumberOfMessages }}
            </app-data-layout-item>
            <app-data-layout-item title="Maximum polling messages">
              {{ maximumMessageCount }}
            </app-data-layout-item>
            <app-data-layout-item title="Polling duration">
              {{ pollingDurationSeconds }}s
            </app-data-layout-item>
            <app-data-layout-item title="Polling progress">
              <div class="progress" *ngIf="polling || messages.length">
                <mat-progress-bar
                  mode="determinate"
                  [value]="pollingProgressPercent"
                ></mat-progress-bar>
                {{ pollingProgressPercent | number: '1.0-0' }}%
              </div>
            </app-data-layout-item>
          </app-data-layout>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Last redrive status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-data-layout>
            <app-data-layout-item title="Date">
              {{
                redriveTask?.startedTimestamp | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ'
              }}
            </app-data-layout-item>
            <app-data-layout-item title="Status">
              {{ redriveTask?.status }}
              <div *ngIf="redriveTask?.failureReason">
                {{ redriveTask?.failureReason }}
              </div>
            </app-data-layout-item>
            <app-data-layout-item
              title="Approximate number of messages to move"
            >
              {{ redriveTask?.approximateNumberOfMessagesToMove }}
            </app-data-layout-item>
            <app-data-layout-item title="Approximate number of messages moved">
              {{ redriveTask?.approximateNumberOfMessagesMoved }}
            </app-data-layout-item>
          </app-data-layout>
        </mat-card-content>
      </mat-card>
      <div class="footer">
        <button
          mat-raised-button
          color="primary"
          class="redrive-button"
          (click)="redriveMessages()"
        >
          Redrive Messages
        </button>
      </div>
    </section>
    <section>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Messages</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-redrive-messages-table
            [messages]="messages"
          ></app-redrive-messages-table>
        </mat-card-content>
      </mat-card>
    </section>
  </div>
</div>
