<div class="home">
  <mat-toolbar class="toolbar mat-elevation-z5">
    <button mat-icon-button class="menu-btn" (click)="navbar.toggle()">
      <mat-icon *ngIf="!navbar.opened">menu</mat-icon>
      <mat-icon *ngIf="navbar.opened">close</mat-icon>
    </button>
    <span>{{ title$ | async }}</span>
    <div class="spacer"></div>
    <button
      mat-icon-button
      (click)="onLogout()"
      matTooltipPosition="before"
      matTooltip="logout"
    >
      <mat-icon>person</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="content">
    <mat-sidenav
      mode="side"
      opened
      fixedInViewport="false"
      class="sidenav"
      #navbar
    >
      <mat-nav-list>
        <a
          class="nav-link"
          mat-list-item
          [routerLink]="['../', 'home', 'dashboard']"
          [class.active]="isActive('/home/dashboard')"
        >
          <mat-icon class="nav-icon">equalizer</mat-icon>
          Home
        </a>
        <a
          mat-list-item
          [routerLink]="['device', deviceManagement.view]"
          [queryParams]="
            deviceManagement.path ? { path: deviceManagement.path } : ''
          "
          [class.active]="isActive('/home/device')"
        >
          <mat-icon class="nav-icon">tune</mat-icon>
          Device Management
        </a>
        <a
          mat-list-item
          [routerLink]="['gateways']"
          [class.active]="isActive('/home/gateways')"
        >
          <mat-icon class="nav-icon">router</mat-icon>
          Gateways
        </a>
        <a
          mat-list-item
          [routerLink]="['catalogue']"
          [class.active]="isActive('/home/catalogue')"
        >
          <mat-icon svgIcon="custom-catalogue" class="nav-icon"></mat-icon>
          Catalogue
        </a>
        <a
          mat-list-item
          [routerLink]="['routing']"
          [class.active]="isActive('/home/routing')"
        >
          <mat-icon class="nav-icon">linear_scale</mat-icon>
          Routing
        </a>
        <a
          mat-list-item
          [routerLink]="['site']"
          [class.active]="isActive('/home/site')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon class="nav-icon">business</mat-icon>
          Site Management
        </a>
        <a
          mat-list-item
          [routerLink]="['interfaces']"
          [class.active]="isActive('/home/interfaces')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon class="nav-icon">alt_route</mat-icon>
          Interface Management
        </a>
        <a
          mat-list-item
          [routerLink]="['users']"
          [class.active]="isActive('/home/users')"
        >
          <mat-icon class="nav-icon">supervised_user_circle</mat-icon>
          User Management
        </a>
        <a
          mat-list-item
          [routerLink]="['alerts']"
          [class.active]="isActive('/home/alerts')"
        >
          <mat-icon class="nav-icon">notifications</mat-icon>
          Alerts
        </a>
        <a
          mat-list-item
          [routerLink]="['redrive']"
          [class.active]="isActive('/home/redrive')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon class="nav-icon">replay</mat-icon>
          Redrive Messages
        </a>
        <a mat-list-item [attr.href]="supportUrl" target="_blank">
          <mat-icon class="nav-icon">contact_support</mat-icon>
          Support
        </a>
      </mat-nav-list>
      <div class="spacer"></div>
      <img
        src="assets/svg/logo-transparent-with-text.svg"
        alt="Solvay logo"
        class="logo logo-sidenav"
      />
      <p class="version">{{ version }}</p>
    </mat-sidenav>

    <mat-sidenav-content>
      <div id="bg1"></div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
