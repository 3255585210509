import { Component, Input, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { firstValueFrom } from 'rxjs';
import { DownloadService } from 'src/app/services/util/download.service';
import { RouteDto } from 'src/models/data-routing.models';
import { FileExtensions, FileTypes } from 'src/models/download.models';
import { DataLoaderService } from '../../../../../services/state/data/data-loader.service';
import { DevicePath } from '../routing-details.component';
import { DataStoreService } from './../../../../../services/state/data/data-store.service';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
})
export class DataListComponent implements OnInit {
  private _displayedData: DevicePath[];
  tooltip = 'copy';
  groupedRoutes: GroupedRoutes[];
  get displayedData() {
    return this._displayedData;
  }

  @Input() set displayedData(v: DevicePath[]) {
    this._displayedData = v;

    const mapper = {};

    this._displayedData.reduce(
      (_: void, curr) => {
        const crumbs = curr.path.split(',')[1].split('/');
        if (crumbs[0] === '') crumbs.splice(0, 2, '/' + crumbs[1]);
        let ref = mapper;
        for (const crumb of crumbs) ref = ref[crumb] = ref[crumb] ?? {};
        return;
      },
      void 0,
    );

    const recur = (ref: object) =>
      Object.entries(ref).map(([key, value]) => ({
        value: key,
        children: recur(value),
      }));

    this.groupedRoutes = recur(mapper);
  }
  @Input() routing: RouteDto;

  constructor(
    private download: DownloadService,
    private data: DataStoreService,
    private loader: DataLoaderService,
  ) {}

  async ngOnInit() {
    if (!this.data.devices) {
      await firstValueFrom(this.loader.loadDevices());
    }
  }

  onCopyToClipBoard(text: string, toolTip: MatTooltip) {
    this.tooltip = 'copied to clipboard';
    setTimeout(() => toolTip.show());
    setTimeout(() => {
      toolTip.hide();
    }, 2000);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  exportAsCSV(): void {
    const site = this.data.sites!.find(
      (site) => site.code === this.routing.pattern.split('/')[1],
    );
    const fileName = site
      ? `${site.country.isoCode} - ${site.name}_${this.routing.name}_IoT-paths_${new Date().toISOString()}`
      : `${this.routing.name}_IoT-paths_${new Date().toISOString()}`;

    const withDeviceDetails = this._displayedData.map((devicePath) =>
      this.addDeviceTypeAndBrand(devicePath),
    );

    this.download.downloadFile(
      this.download.convertToCSV(withDeviceDetails),
      fileName,
      FileTypes.CSV_TYPE,
      FileExtensions.CSV_EXTENSION,
    );
  }

  resetTooltip() {
    this.tooltip = 'copy';
  }

  getDeviceName(id: string): string {
    const device = this.data.getDeviceByID(id);
    return device ? device.metadata.name : '';
  }

  addDeviceTypeAndBrand(devicePath: DevicePath): string {
    const device = this.data.devices!.find(
      (d) => d.device_id === devicePath.device.device_id,
    );
    if (!device) {
      throw new Error(`Unknown device ${devicePath.device.device_id}`);
    }
    return `${devicePath.path},${device.device_type.brand.name},${device.device_type.metadata.label}`;
  }
}

type GroupedRoutes = { value: string; children: GroupedRoutes[] };
