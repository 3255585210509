import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-layout-item',
  templateUrl: './data-layout-item.component.html',
  styleUrls: ['./data-layout-item.component.scss'],
})
export class DataLayoutItemComponent {
  @Input() title: string;
}
