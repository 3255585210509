import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/services/shared.module';
import { RedriveMessagesSettingsComponent } from './redrive-messages-settings/redrive-messages-settings.component';
import { RedriveMessagesTableComponent } from './redrive-messages-table/redrive-messages-table.component';
import { RedriveMessagesComponent } from './redrive-messages.component';

@NgModule({
  declarations: [
    RedriveMessagesComponent,
    RedriveMessagesTableComponent,
    RedriveMessagesSettingsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
})
export class RedriveMessagesModule {}
