<h2 mat-dialog-title>Poll settings</h2>
<div mat-dialog-content [formGroup]="form">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Maximum polling messages</mat-label>
      <input
        matInput
        type="number"
        formControlName="maximumMessageCount"
        class="right-align"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Polling duration</mat-label>
      <input
        matInput
        type="number"
        formControlName="pollingDurationSeconds"
        class="right-align"
      />
      <span matTextSuffix>s</span>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    color="primary"
    (click)="submit()"
    cdkFocusInitial
    [disabled]="form.invalid"
  >
    Confirm
  </button>
</div>
