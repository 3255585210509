import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/services/shared.module';
import { SelectPositionComponent } from './site-creation/select-position/select-position.component';
import { SiteCreationComponent } from './site-creation/site-creation.component';
import { SiteFilterComponent } from './site-filter/site-filter.component';
import { SiteComponent } from './site.component';

@NgModule({
  declarations: [
    SiteComponent,
    SiteCreationComponent,
    SiteFilterComponent,
    SelectPositionComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
  ],
})
export class SiteModule {}
