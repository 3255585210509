import { Component, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { Gateway } from 'src/models/gateway.models';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';
import { GatewayDataVisualComponent } from './gateway-data-visual/gateway-data-visual.component';

@Component({
  selector: 'app-gateway-list',
  templateUrl: './gateway-list.component.html',
  styleUrls: ['./gateway-list.component.scss'],
})
export class GatewayListComponent {
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredGateways$ = this.data.gateways$;
  gateways$ = this.data.gateways$;
  sites$ = this.data.sites$;
  selectedGateway: Gateway[];
  loading = true;
  emptyList = true;

  constructor(
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private dataLoader: DataLoaderService,
    public dialog: MatDialog,
    public auth: AuthService,
  ) {
    this._loadData();
  }

  private _loadData() {
    combineLatest([
      this.dataLoader.loadGateways(),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.emptyList = false;
      });
  }

  public updateFilteredGateways(gateways: Gateway[]): void {
    this.filteredGateways$ = of(gateways);
    this.emptyList = gateways && !(gateways.length > 0);
  }

  public onShowDataVisual(gateway: Gateway) {
    this.dialog.open(GatewayDataVisualComponent, {
      data: { gw: gateway.id },
    });
  }

  public openMap(templateRef: TemplateRef<unknown>, gateway: Gateway) {
    this.selectedGateway = [gateway];
    this.dialog.open(templateRef);
  }

  public networkActive(gateway: Gateway): boolean {
    return gateway.networkActive;
  }

  public gatewayIsActive(lastCheckin: string): boolean {
    const allowedTimeout = 14400000; // 4hours in milliseconds
    return Date.now() - Date.parse(lastCheckin) < allowedTimeout;
  }
}
